import React, { forwardRef } from "react";
import CustomButton from "./Button";
import FeatureBox from "./FeatureBox";
import PropTypes from "prop-types";
import styles from "./TopSection.module.css";

const TopSection = forwardRef(({ className = "" }, ref) => {
  const handleSignUp = () => {
    console.log("Sign up button clicked");
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  const handleLogIn = () => {
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  return (
    <section ref={ref} className={[styles.frameParent, className].join(" ")}>
      <div className={styles.backgroundImageParent}>
        <div className={styles.backgroundImage}></div>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <img
              className={styles.logoIcon}
              loading="lazy"
              alt=""
              src="/logo.svg"
            />

            <div className={styles.signUpLogin}>
              <CustomButton
                propBackgroundColor="transparent"
                propHeight="3.125rem"
                // propFlex="1"
                propBorderRadius="31px"
                propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
                propPadding="8px 13px 8px 18px"
                filledButtonCTA="sign up"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleSignUp}
              />
              <CustomButton
                propBackgroundColor="#FF8C00"
                propHeight="3.125rem"
                // propFlex="0.7564"
                propBorderRadius="31px"
                propBackground="#FF8C00"
                propPadding="8px 25px"
                filledButtonCTA="log in"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleLogIn}
              />
            </div>
          </div>
          <div className={styles.sidekickContentWrapper}>
            <div className={styles.backgroundContainer}>
              <img alt="" src="/logo_with_lines.svg" />
            </div>
            <div className={styles.introBigContainer}>
              <div className={styles.introBig}>
                <h1 className={styles.meetYourDna}>
                  Meet your DNA assembly sidekick.
                </h1>
              </div>
              <div className={styles.introTextBodyContainer}>
                <b className={styles.introTextBody}>
                  <p className={styles.introText}>
                    LabKick is the intuitive, end-to-end copilot built to
                    supercharge your cloning capabilities.
                  </p>
                  {/* <p className={styles.introText}>
                    It’s a natural-language tool made by real biologists for
                    real biologists.
                  </p> */}
                </b>
              </div>
            </div>
          </div>
          <div className={styles.videoSection}>
            <div className={styles.videoPlaceholder}>
              <div className={styles.heroImagevideo}>
                <img
                  className={styles.iconPlayCircle}
                  loading="lazy"
                  alt=""
                  src="/-icon-play-circle.svg"
                />
              </div>
            </div>
            <div className={styles.customButtonWrapper}>
              <CustomButton
                filledButtonCTA="get started"
                onClick={handleLogIn}
              />
            </div>
          </div>

          <div className={styles.examplesWithIcons}>
            <FeatureBox
              header="Use natural language."
              text="Work like a human, not a robot, without complicated interfaces or endless drop-downs."
              icon="/group-48.svg"
            />
            <FeatureBox
              header="Boost your productivity."
              text="Quickly validate your designs and guard against common errors with just a few clicks."
              icon="/lightbulb.svg"
            />
            <FeatureBox
              header="Work how<br />you want."
              text="Assemble sequences using your favorite methods (Gibson, Golden Gate, PCR, etc.)."
              icon="/group-52.svg"
            />
            <FeatureBox
              header="Create plans automatically."
              text="Easily generate human-readable protocols, order forms, schedules to-do lists and more."
              icon="/group-46.svg"
            />
            <FeatureBox
              header="Annotate<br />with ease."
              text="Our feature database makes it easy to turn ATCG strings into a feature-rich Genbank format."
              icon="/group-41.svg"
            />
            <FeatureBox
              header="Keep your<br />files tidy."
              text="Store all your plans and sequences in one place, never more than one @ away."
              icon="/broom.svg"
            />
          </div>
          <div className={styles.dividerText}>
            <h1 className={styles.canYouKickContainer}>
              <span>Can you kick it?</span>
              <span className={styles.span}>{` `}</span>
              <span className={styles.yesYouCan}>Yes, you can.</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
    // comment
  );
});

TopSection.propTypes = {
  className: PropTypes.string,
};

export default TopSection;
