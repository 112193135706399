import React, { forwardRef } from 'react';
import styles from "../pages/Desktop.module.css";
import PaymentTierOne from "../components/PaymentTierOne";
import PaymentTierTwo from "../components/PaymentTierTwo";
import PaymentTierThree from "../components/PaymentTierThree";

const PaymentSection = forwardRef(({ className = "" }, ref) => {
  return (
    <div ref={ref} className={styles.paymentPanel}>
      <section className={styles.paymentPanel}>
        <div className={styles.paymentPanelChild} />
        <div className={styles.paymentContent}>
          <div className={styles.labStatement}>
            <h1 className={styles.builtForYour}>Built for your lab.</h1>
            <b className={styles.whetherYouKick}>
              Whether you kick it solo or with a big research team, LabKick’s
              ready to assist you.
            </b>
          </div>
        </div>
        <div className={styles.pricingTiers}>
          <PaymentTierOne />
          <PaymentTierTwo />
          <PaymentTierThree />
        </div>
      </section>
    </div>
  );
});

export default PaymentSection;
