import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./GroupComponent.module.css";

const GroupComponent = ({
  className = "",
  propTop,
  propGap,
  question,
  propPadding,
  answer,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      top: propTop,
      gap: propGap,
    };
  }, [propTop, propGap]);

  const fAQIconsStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div
      className={[styles.question, className].join(" ")}
      style={groupDivStyle}
    >
      <b className={styles.whatTypesOf}>{question}</b>
      <div className={styles.fAQContent}>
        <div className={styles.fAQIcons} style={fAQIconsStyle}>
          <img
            className={styles.labkickIcon}
            loading="lazy"
            alt=""
            src="/labkickicon@2x.png"
          />
        </div>
        <div className={styles.answer}>{answer}</div>
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,

  /** Style props */
  propTop: PropTypes.any,
  propGap: PropTypes.any,
  propPadding: PropTypes.any,
};

export default GroupComponent;
