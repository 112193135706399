import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styles from './CookbookArticle.module.css';

// Import cookbookItems from Cookbook.js
import { cookbookItems } from './Cookbook';

function CookbookArticle() {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const article = cookbookItems.find(item => 
    item.title.toLowerCase().replace(/\s+/g, '-') === slug
  );

  useEffect(() => {
    if (article) {
      console.log("Article found:", article); // Add this line
      console.log("Download URL:", article.downloadUrl); // Add this line
      import(`../content/articles/${article.contentFile}`)
        .then(res => {
          fetch(res.default)
            .then(response => response.text())
            .then(text => setContent(text));
        })
        .catch(err => console.log(err));
    }
  }, [article]);

  if (!article) {
    return <div>Article not found</div>;
  }

  const handleOpenWithLabKick = () => {
    if (article.redirectId) {
      window.open(`https://app.labkick.ai/?${article.redirectId}=true`, '_blank');
    }
  };

  const handleImageClick = (src) => {
    setFullscreenImage(src);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  const handleDownload = () => {
    if (article.downloadUrl) {
      window.open(article.downloadUrl, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{article.title}</h1>
      <div className={styles.metaContainer}>
        <div className={styles.leftContent}>
          <div className={styles.authorInfo}>
            <img src={article.image} alt={article.author} className={styles.avatar} />
            <span className={styles.authorName}>{article.author}</span>
            <span className={styles.date}>{article.date}</span>
          </div>
          <div className={styles.tags}>
            {article.tags.map((tag, index) => (
              <span key={index} className={styles.tag} data-type={tag}>{tag}</span>
            ))}
          </div>
        </div>
        <button className={styles.openButton} onClick={handleOpenWithLabKick}>
          <img src="/LabKick_Favicons_All_LabKick_Fav_192.svg" alt="LabKick Logo" className={styles.buttonLogo} />
          Open with LabKick
        </button>
      </div>
      <hr className={styles.divider} />
      <div className={styles.content}>
        <ReactMarkdown
          className={styles.content}
          components={{
            img: ({node, ...props}) => (
              <img 
                {...props} 
                className={styles.contentImage} 
                onClick={() => handleImageClick(props.src)}
              />
            )
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
      {article.downloadUrl ? (
        <button 
          className={styles.openButton} // Changed from styles.downloadButton to styles.openButton
          onClick={handleDownload} // Use the handleDownload function
        >
          <img src="/LabKick_Favicons_All_LabKick_Fav_192.svg" alt="Download Icon" className={styles.buttonLogo} />
          Download Files
        </button>
      ) : (
        <p>No download URL available</p>
      )}
      {fullscreenImage && (
        <div className={styles.fullscreenOverlay} onClick={handleCloseFullscreen}>
          <img src={fullscreenImage} alt="Fullscreen" className={styles.fullscreenImage} />
          <span className={styles.closeButton}>&times;</span>
        </div>
      )}
    </div>
  );
}

export default CookbookArticle;
