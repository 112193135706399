import GroupComponent from "./GroupComponent";
import PropTypes from "prop-types";
import styles from "./BenefitItem.module.css";

const BenefitItem = ({ className = "" }) => {
  return (
    <section className={[styles.benefitItem, className].join(" ")}>
      <div className={styles.questions}>
        <div className={styles.questionTitle}>
          <h1 className={styles.gotQuestionsWere}>
            Got questions? We’re all about answers.
          </h1>
        </div>
        <div className={styles.faq}>
          <GroupComponent
            question="What types of cloning do you support?"
            answer="Lorem ipsum dolor sit amet, consectetur edipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse dui libero, commodo sit amet est a, efficitur maximus velit. Etiam tempor convallis tortor, sit amet molestie libero bibendum in. Sed vel ultrices neque, ac hendrerit justo."
          />
          <GroupComponent
            question="What’s next on your feature roadmap?"
            answer="We’re working hard to expand both our cloning options (CRISPR, Gateway, TA, InFusion, NEB HiFi, TOPO, etc.) plus a bunch of design features (DNA design, library design & mutagenesis, experimental design, protein design, etc.) and debugging capabilities."
          />
          <GroupComponent
            question="What's different about LabKick?"
            answer="We’re working hard to expand both our cloning options (CRISPR, Gateway, TA, InFusion, NEB HiFi, TOPO, etc.) plus a bunch of design features (DNA design, library design & mutagenesis, experimental design, protein design, etc.) and debugging capabilities."
          />
          <GroupComponent
            question="Can I make new feature suggestions?"
            answer="You sure can! Send your big idea to admin@labkick.ai and we’ll be in touch."
          />
          <GroupComponent
            question="Is my data secure?"
            answer="We do our best to squish the bugs ahead of time, but sometimes one slips through. If you find one, we’d love for you to let us know at support@labkick.ai. Screenshots and descriptions are always appreciated!"
          />
          <GroupComponent
            question="What do I do if I find a software bug?"
            answer="We do our best to squish the bugs ahead of time, but sometimes one slips through. If you find one, we’d love for you to let us know at support@labkick.ai. Screenshots and descriptions are always appreciated!"
          />
        </div>
      </div>
    </section>
  );
};

BenefitItem.propTypes = {
  className: PropTypes.string,
};

export default BenefitItem;
