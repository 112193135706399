import PropTypes from "prop-types";
import styles from "./FrameComponent2.module.css";
import CustomButton from "./Button";

const handleLogIn = () => {
  window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
};

const FrameComponent2 = ({ className = "" }) => {
  return (
    <section className={[styles.imageDescriptionParent, className].join(" ")}>
      <div className={styles.imageDescription}>
        <div className={styles.footerCtaShapeParent}>
          <h1 className={styles.footerCta}>Let's get kicking.</h1>
          <CustomButton
            propBackgroundColor="transparent"
            propHeight="3.125rem"
            propFlex="0"
            propBorderRadius="31px"
            propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
            propPadding="8px 13px 8px 18px"
            filledButtonCTA="sign up"
            propTextAlign="center"
            propTextDecoration="none"
            onClick={handleLogIn} // Ensure this prop is correctly passed
            className={styles.signUpButton}
          />
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerTop}>
          <div className={styles.footerBranding}>
            <img
              className={styles.footerLogoIcon}
              loading="lazy"
              alt=""
              src="/logo.svg"
            />
            <div className={styles.labkickAllRights}>
              © 2024 LabKick. All rights reserved.
            </div>
          </div>
          <div className={styles.footerLinks}>
            <div className={styles.socialIcons}>
              {/* <img
                className={styles.image6Icon}
                loading="lazy"
                alt=""
                src="/image-6@2x.png"
              /> */}
              <a
                href="https://x.com/LabKickAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.image6Icon}
                  loading="lazy"
                  alt=""
                  src="/image-7@2x.png"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/labkick/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.image6Icon}
                  loading="lazy"
                  alt=""
                  src="/image-8@2x.png"
                />
              </a>
            </div>
            <div className={styles.footerLinksMobile}>
              <a href="mailto:info@labkick.ai" className={styles.contactInfo}>
                Contact info
              </a>
              <a
                href="/privacy_policy_labkick.html"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.legalStuff}
              >
                Privacy Policy
              </a>
              <a
                href="/terms_of_service_labkick.html"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.legalStuff + " " + styles.termsOfService}
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
