import PropTypes from "prop-types";
import styles from "./PaymentTierThree.module.css";

const PaymentTierThree = ({ className = "" }) => {
  const handleContactUs = () => {
    window.location.href = "mailto:sales@labkick.ai";
  };

  return (
    <div className={[styles.paymentTierThree, className].join(" ")}>
      <div className={styles.paymentTierThreeChild} />
      <div className={styles.enterpriseContent}>
        <h2 className={styles.enterprise}>Enterprise</h2>
      </div>
      <div className={styles.enterpriseDescription}>
        <b className={styles.customSolutionsAvailableForContainer}>
          <p className={styles.customSolutionsAvailable}>
            Custom solutions available
          </p>
          <p className={styles.customSolutionsAvailable}>
            for large teams and labs
          </p>
        </b>
      </div>
      <button className={styles.signUp} onClick={handleContactUs}>
        <div className={styles.contactUs}>contact us</div>
      </button>
      <div className={styles.security}>
        <div className={styles.planInfo}>
          <b className={styles.unlimitedChatsPerContainer}>
            <p className={styles.customSolutionsAvailable}>
              Unlimited chats per month
            </p>
            <p className={styles.customSolutionsAvailable}>
              Unlimited designs per month
            </p>
          </b>
          <div className={styles.divider} />
        </div>
        <div className={styles.enterpriseGradeSecurityElnContainer}>
          <p className={styles.enterpriseGradeSecurity}>
            Enterprise-grade security
          </p>
          <p className={styles.customSolutionsAvailable}>
            <span
              className={styles.elnProject}
            >{`ELN & project management`}</span>
            <span className={styles.elnProject}>{"software integrations"}</span>
          </p>
          <p className={styles.enterpriseGradeSecurity}>
            Beta access to new features
          </p>
        </div>
      </div>
    </div>
  );
};

PaymentTierThree.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierThree;
