import PropTypes from "prop-types";
import styles from "./PaymentTierTwo.module.css";

const PaymentTierTwo = ({ className = "" }) => {
  const handlePurchase = () => {
    window.open(
      "https://app.labkick.ai",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className={[styles.paymentTierTwo, className].join(" ")}>
      <div className={styles.paymentTierTwoChild} />
      <div className={styles.professionalContent}>
        <h2 className={styles.professional}>Professional</h2>
        <div className={styles.professionalPricing}>
          <b className={styles.priceSymbol}>$10</b>
          <b className={styles.perLabchip}>per labchip</b>
        </div>
        <button className={styles.signUp} onClick={handlePurchase}>
          <div className={styles.purchaseNow}>purchase now</div>
        </button>
      </div>
      <div className={styles.professionalDetails}>
        <b className={styles.chatsPerMonthContainer}>
          <p className={styles.chatsPerMonth}>100 chats per month</p>
          <p className={styles.chatsPerMonth}>3 designs per month</p>
        </b>
        <div className={styles.securityHighlight}>
          <div className={styles.divider} />
          <div className={styles.chatsPerMonthContainer}>
            Enterprise-grade security
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentTierTwo.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierTwo;
