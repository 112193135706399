import PropTypes from "prop-types";
import styles from "./PaymentTierOne.module.css";

const PaymentTierOne = ({ className = "" }) => {
  const handleSignUp = () => {
    window.open(
      "https://app.labkick.ai",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className={[styles.paymentTierOne, className].join(" ")}>
      <div className={styles.paymentTierOneChild} />
      <div className={styles.academicContent}>
        <h2 className={styles.academic}>Academic</h2>
        <div className={styles.academicPricing}>
          <h1 className={styles.free}>FREE</h1>
          <b className={styles.withValidEdu}>with valid .edu email</b>
        </div>
        <button className={styles.signUp} onClick={handleSignUp}>
          <div className={styles.signUpNow}>sign up now</div>
        </button>
      </div>
      <b className={styles.chatsPerMonthContainer}>
        <p className={styles.chatsPerMonth}>1000 chats per month</p>
        <p className={styles.chatsPerMonth}>100 designs per month</p>
      </b>
    </div>
  );
};

PaymentTierOne.propTypes = {
  className: PropTypes.string,
};

export default PaymentTierOne;
