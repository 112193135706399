import React, { forwardRef } from "react";
import styles from "../pages/Desktop.module.css";
import ActionBox from "./ActionBox";

const SecondSection = forwardRef(({ className = "" }, ref) => {
  return (
    <div ref={ref} className={styles.secondSection}>
      <div className={styles.coloredLinesFixed}>
        <img src="/colored-line-shapes.svg" alt="LabKick" />
      </div>
      <div className={styles.splashStatement}>
        <b className={styles.splashStatementText}>
          You’re a scientist, not a prompt engineer. LabKick’s easy-to-use text
          interface is designed to simplify the steps between where you are and
          where you’re headed. Plasmids, microbes, colonies—whatever. If you can
          sequence it, you can kick it.
        </b>
      </div>
      <section className={styles.backgroundParent}>
        <div className={styles.background} />
        <div className={styles.gradient} />
      </section>

      <ActionBox
        direction="right"
        header="Upload your sequences."
        body="Create a combinatorial set of different promoters and ribosome binding sites to express GFP. (Did you think we WOULDN’T use GFP as our toy example?)"
        image="/example@2x.png"
      />
      <ActionBox
        direction="left"
        header="Describe your plan."
        body="Just tell LabKick what you want to build. Whether you’re subcloning a single gene or creating a multi-part combinatorial library, you can do it all using your favorite assembly methods and kits."
        image="/example@2x.png"
      />
      <ActionBox
        direction="right"
        header="Validate your design."
        body="Check your design quickly with intuitive tools designed for efficient work. If you find an error or change your mind, a revised design is just a prompt away."
        image="/example@2x.png"
      />
      <ActionBox
        direction="left"
        header="Share your outputs."
        body="Download to review your protocols, gantt charts, order forms, budgets and more. And don’t worry: the results are all nicely styled to be readable by humans."
        image="/example@2x.png"
      />
    </div>
  );
});

export default SecondSection;
